import {
  disp_spinner_button,
  disp_spinner_icon,
  disp_flashing,
  disp_message,
  disp_date_hr_for_area,
  disp_date_hr,
  url_link_converter,
} from "../common/ui"

import {
  disp_open_file,
} from "../common/file_upload"

import {
  submit_message,
  submit_message_with_ai,
  submit_reply_message,
  submit_ai_message
} from "./message_submit"

import {
  received_speak,
  received_update,
  received_delete,
  received_before_histroys,
  received_after_histroys,
  received_init_messages,
  received_ai,
  received_error
} from "./message_receive"

import {
  setting_reply_event,
  received_reply,
  received_replys,
  create_reply_input,
  setting_reply_links
} from "./message_reply"

import {
  init_disp_messages,
  init_pager,
  go_to_top,
  go_to_bottom,
  scroll_to_message,
  get_message_reply_area_dom,
  get_message_reply_form_dom,
  scroll_to_reply,
  setting_translate_event,
  setting_ai_event,
} from "./message_common"

import {
  chatChannel,
  is_connect
} from "../channels/chat_channel"

export {
  chatChannel,
  is_connect
}


$(function () {

  if ($(".chat").length <= 0) {
    return;
  } else if ($(".company_search").length > 0) {
    return;
  }

  // form内のエンターキー抑止　ctrl+enterは許可
  $("input").on("keydown", function (e) {
    if (!event.ctrlKey) {
      if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
        return false;
      }
    }
  });

  $('.js-select').select2({
    width: '100%',
    placeholder: "タグ選択"
  })
  $('.js-select').next(".select2").find("input").attr("readonly", "true");

  $(document).on('video_chat_info', function (e, data) {
    $('#lesson').empty();
    $('#lesson').append(data['message']['html'])
  })

  disp_flashing("#message_image", "#chatsubmit", "#chatsubmit");
  disp_open_file("#message_image", "#upfilename");

  init_disp_messages(gon.messages);

  init_pager(gon.messages, gon.pager_info);

  go_to_top(); // 「▲」ボタン押下時スクロールイベントを設定
  go_to_bottom(); // 「▲」ボタン押下時スクロールイベントを設定

  setting_translate_event();

  if (gon.select_parent_message_id && gon.select_message_id) {
    scroll_to_reply(gon.select_message_id, gon.select_parent_message_id);
  } else if (gon.select_message_id) {
    // スクロール
    $(window).on('load', function () {
      scroll_to_message(gon.select_message_id);
    });
  }

  // テキストエリアでCTRL+ENTERした場合にメッセージ送信
  $(window).keydown(function (e) {
    if (event.ctrlKey && e.keyCode === 13) {
      submit_message("#message_body", "#message_image", "#tag");
    }
  });

  // 送信ボタン押下でメッセージ送信
  $("#chatsubmit").click(function () {
    submit_message("#message_body", "#message_image", "#tag");
  });

  // AI先生に質問送信ボタン押下でメッセージ送信
  $("#chatsubmit_with_ai").click(function () {
    submit_message_with_ai("#message_body", "#message_image", "#tag");
  });

  // メッセージ受信時のイベントを設定
  $(document).on('received_speak', received_speak);
  $(document).on('received_update', received_update);
  $(document).on('received_delete', received_delete);
  $(document).on('received_reply', received_reply); // ActionCable経由でリアルタイムに受信したメッセージ
  $(document).on('received_ai', received_ai);
  $(document).on('received_error', received_error);
  $(document).on('ajax:success', function (event) { // ajaxのresponse
    var data = event.detail[0];

    if (data['mode'] == "reply") {
      var data = data['result'];
      var messages = data.messages;

      // リプライ表示
      received_replys(event, messages);

    } else if (data['mode'] == "before_histroy") {
      var data = data['result'];
      received_before_histroys(event, data)
    } else if (data['mode'] == "after_histroy") {
      var data = data['result'];
      received_after_histroys(event, data)
    } else if (data['mode'] == "init") {
      var data = data['result'];
      received_init_messages(event, data)
    }

  });

})