export {
    disp_spinner_button,
    disp_spinner_button_with_disable,
    disp_spinner_icon,
    disp_flashing,
    disp_message,
    disp_messages,
    disp_date_hr_for_area,
    disp_date_hr,
    disp_add_form,
    is_display,
    disp_alert,
    url_link_converter,
}

/**
 * is_disabledがtrueのとき、ボタンにスピナーボタンを追加します。
 * @param {*} button_selector
 * @param {*} is_disabled
 */
function disp_spinner_button(button_selector, is_disabled) {

    var button = $(button_selector);
    var spinner = $(".spinner");

    if (spinner.length <= 0) {
        spinner = button.clone().hide();
        spinner.removeAttr("id").addClass("spinner").attr("disabled", "true");
        spinner.find("img").remove();
        var spinner_image = $("<span>").addClass("spinner-border spinner-border-sm");
        spinner.append(spinner_image);
        button.after(spinner);
    }

    // 非活性の場合
    if (is_disabled == true) {
        button.hide();
        spinner.show();

        // 活性の場合
    } else {
        spinner.hide();
        button.show();
    }
}

/**
 * is_disabledがtrueのとき、ボタンにスピナーボタンを追加します。
 * @param {*} button_selector
 * @param {*} disable_button_selector ※スピナー表示ボタンと同時に無効化したいボタンを指定（無い場合はnull,空文字）
 * @param {*} is_disabled
 */
function disp_spinner_button_with_disable(button_selector, disable_button_selector, is_disabled) {

    var button = $(button_selector);
    var spinner = button.find(".spinner-border");

    if (spinner.length <= 0) {
        spinner = $("<span>").addClass("spinner-border spinner-border-sm me-2").hide();
        button.prepend(spinner);
    }

    if (is_disabled == true) {
        button.prop('disabled', true);
        spinner.show();
    } else {
        spinner.hide();
        button.prop('disabled', false);
    }

    // 別ボタンの無効化制御（disable_button_selectorが存在する場合のみ実行）
    if (disable_button_selector && disable_button_selector.trim() !== '') {
        var disable_button = $(disable_button_selector);
        if (is_disabled) {
            disable_button.prop('disabled', true);
        } else {
            disable_button.prop('disabled', false);
        }
    }
}

/**
 * is_disabledがtrueのとき、ボタンにスピナーボタンを追加します。
 * @param {*} icon_selector
 * @param {*} is_disabled
 */
function disp_spinner_icon(icon_selector, is_disabled) {

    var icon = $(icon_selector);
    var spinner = icon.find(".spinner-border");

    // 非活性の場合
    if (is_disabled == true) {
        icon.prop("disabled", true);
        icon.css("pointer-events", "none");
        icon.find("img").hide();
        spinner.show();
    // 活性の場合
    } else {
        icon.prop("disabled", false);
        icon.css("pointer-events", "auto");
        icon.find("img").show();
        spinner.hide();
    }
}

/**
 * ボタンを点滅させます。
 * @param {*} inputfile_selector 
 * @param {*} button_selector 
 * @param {*} stop_selector 
 */
function disp_flashing(inputfile_selector, button_selector, stop_selector) {

    var button = $(button_selector);
    var interval = null;

    // 点滅終了
    if (interval != null) {
        clearInterval(interval);
    }

    $(inputfile_selector).change(function () {

        // 点滅終了
        if (interval != null) {
            clearInterval(interval);
        }

        //画像ファイル選択した場合
        if ($(this).get(0).files.length > 0) {

            // デザイン変更
            button.addClass("btn-danger");
            button.removeClass("btn-primary");

            // 点滅開始
            interval = setInterval(function () {
                button.fadeOut(500, function () {
                    $(this).fadeIn(500)
                });
            }, 1000);

            // 画像ファイルが存在しない場合
        } else {
            // デザイン変更
            button.addClass("btn-primary");
            button.removeClass("btn-danger");
        }
    });

    $(stop_selector).click(function () {
        // 点滅終了
        if (interval != null) {
            clearInterval(interval);
        }
        // デザイン変更
        button.addClass("btn-primary");
        button.removeClass("btn-danger");
    })

}

function disp_messages(messages, message_area) {
    var message_doms = []
    if (message_area) {
        messages.forEach(function (message) {
            message_doms.push(disp_message(message, message_area))
        });

    } else {
        messages.forEach(function (message) {
            message_doms.push(disp_message(message))
        });
    }
    return message_doms
}

function disp_message(message, message_area) {

    // テンプレートのコピー
    var message_temp = $(".message.temp").clone().removeClass("temp");

    // メッセージ内容表示
    message_temp.find(".course_name").text(message.course.name); //チャンネル名
    var email = "削除済みユーザ";
    if (message.user) {
        email = message.user.email
    }
    message_temp.find(".user").text(email); // メールアドレス
    message_temp.find(".message_body").html(url_link_converter(message.body)); // メッセージ内容
    message_temp.find(".message_content").attr("data-message_id", message.id); // メッセージIDをdata属性として付与
    message_temp.find(".message_content").attr("data-parent_message_id", message.parent_message_id); // 親（リプライ元）メッセージIDをdata属性として付与
    message_temp.find(".date").attr("data-year", message.create_year).attr("data-day", message.create_day).attr("data-hour", message.create_hour); // メッセージ日付をdata属性として付与
    message_temp.find(".year").text(message.create_year);
    message_temp.find(".day").text(message.create_day);
    message_temp.find(".hour").text(message.create_hour);
    message_temp.find(".message_id").attr("value", message.id);

    // 自分のメッセージの場合
    if (email == $("#user").data("user")) {
        // 右に表示
        message_temp.addClass("self-message justify-content-end");
    } else {
        // 自分のメッセージではない場合編集ボタン、AIボタンを削除
        message_temp.find(".edit_button").remove();
        message_temp.find(".ai_button").remove();
    }

    // メッセージ画像表示
    if (message.image_url) {
        var img = $("<img>").attr("src", message.image_url);
        message_temp.find(".message_image").attr("href", message.image_url).append(img);
    }

    //　編集済み表示
    if (message.updated) {
        var updatedAt = new Date(message.updated_at);
        var year = updatedAt.getFullYear();
        var month = ('0' + (updatedAt.getMonth() + 1)).slice(-2);
        var day = ('0' + updatedAt.getDate()).slice(-2);
        var hours = ('0' + updatedAt.getHours()).slice(-2);
        var minutes = ('0' + updatedAt.getMinutes()).slice(-2);
        var formattedUpdatedAt = year + '/' + month + '/' + day + ' ' + hours + ':' + minutes;
        var updated = message_temp.find(".updated");
        updated.text("(" + "編集済み " + formattedUpdatedAt + ")").removeClass("hidden");
    }


    var reply_link = message_temp.find(".reply_link");
    if (message.has_reply) {
        // reply_link.find(".hide_reply_link").hide();
        reply_link.show();
    } else {
        reply_link.hide();
    }

    // タグ表示
    var message_tags = message.message_tag
    message_tags.forEach(function (message_tag) {
        var tag_name = $("<span>").text(message_tag.tag.name).addClass("tag badge badge-pill badge-info").attr("data-tag_id", message_tag.tag.id);
        message_temp.find(".message_tags").append(tag_name);
    });

    // メッセージリンク化
    if (message_temp.prop("tagName") == "A") {
        var query = "?messageid=" + message.id;
        if (message.parent_message_id) {
            query = query + "&parentid=" + message.parent_message_id
        }
        var url = "/chat/" + message.course.id + "/chat/";

        message_temp.attr("href", url + query);
    }



    if (message_area) {
        $(message_area).append(message_temp);
    }

    return message_temp;

}

function disp_date_hr_for_area(message_area) {
    message_area.find(".datehr").remove();

    message_area.find(".message").each(function () {
        disp_date_hr($(this));
    })

}

function disp_date_hr(message) {

    var prev_message = $(message).prevAll(".message").first();

    var prev_date = prev_message.find(".date");
    var prev_year = prev_date.data("year");
    var prev_day = prev_date.data("day");

    var date = $(message).find(".date");
    var year = date.data("year");
    var day = date.data("day");

    if (prev_year + prev_day != year + day) {
        if (!year || !day) {
            return;
        }
        var text = $("<span>").text(year + day);
        var date_hr = $("<div>").addClass("datehr").append("<hr>").append(text);

        $(message).before(date_hr);
    }

}

function disp_add_form(plus_button_selector, form_temp_selector, form_area_selector, max_form_count = 10, input_name = "input", minus_button_selector = "", ) {

    $(plus_button_selector).click(function () {

        var form_count = $(form_area_selector).find(".added_form").length
        if (form_count >= max_form_count) {
            return;
        }

        var form = $(form_temp_selector).clone();
        var input = form.find("input,select,textarea");


        if (input.length == 1) {
            var name = input_name + "[]"
            $(input).attr("name", name).attr("required", true);

        } else if (input.length > 1) {
            var input_count = 0;
            input.each(function () {
                input_count++;
                var id = input_name + "_" + form_count + "_" + input_count;
                $(this).attr("id", id).attr("name", id).attr("required", true);
            });
        }
        if (minus_button_selector) {
            form.find(minus_button_selector).on('click', function () {
                $(this).parent().remove();
            });
        }
        form.removeClass(form_temp_selector.replace(/\./, ''));
        form.addClass("added_form")

        $(form_area_selector).append(form);
        form.removeClass("hidden");

    });

    $(minus_button_selector).on('click', function () {
        $(this).parent().remove();
    });
}

function is_display(body_class_name) {
    return $(body_class_name).length > 0
}

function disp_alert(body, prepend_element_selector, style) {

    if (!prepend_element_selector) {
        prepend_element_selector = $(".wrapper");
    }
    $(".alert").remove();

    var alert = $(".alert-temp").clone();
    alert.addClass("alert");
    alert.find(".alert-body").text(body);
    $(prepend_element_selector).prepend(alert);

    if (style) {
        alert.attr('style', style);
    }


    $(alert).show();

}

function url_link_converter(text) {
    var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
    var newText = text.replace(urlRegex, function(url) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>';
    });
    
    return newText;
}

$(function () {
    $('.datepicker').datepicker({
        dateFormat: "yy/mm/dd"
    });

});